export const environment = {
  production: true,
  pushNotificationsEnabled: false,
  version: "2.0.2-106030",
  featureFlag: {
    co2: true,
  },
  dependencies: {
    domain: "tmeshdev.bitme.sh",
    signal_api: {
      url: "https://api.bitme.sh/signal",
      domain: "api.bitme.sh",
    },
    entity_api: {
      url: "https://api.bitme.sh/entity",
      domain: "api.bitme.sh",
    },
    security_api: {
      url: "https://api.bitme.sh/security",
      domain: "api.bitme.sh",
    },
    notifications_api: {
      url: "https://api.bitme.sh/entity",
      domain: "api.bitme.sh",
    },
    termoobject_api: {
      url: "https://api.bitme.sh/entity",
      domain: "api.bitme.sh",
    },
    tempreports_api: {
      url: "https://api.bitme.sh/tempcontrol",
      domain: "api.bitme.sh",
    },
    checklist_api: {
      url: "https://api.bitme.sh/checklist",
      domain: "api.bitme.sh",
    },
    signature_api: {
      url: "https://api.bitme.sh/checklist",
      domain: "api.bitme.sh",
    },
    fileupload_api: {
      url: "https://api.bitme.sh/file",
      domain: "api.bitme.sh",
    },
  },
  clientId: 'ad5c663b-e9a1-41d6-b9c2-21276dbb29db',
  redirectUrl: 'https://tmeshdev.bitme.sh/login',
  protectedResourceMap: new Map<string, Array<string>>([
    [
      "https://api.bitme.sh/signal", ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"],
    ],
    [
      "https://api.bitme.sh/entity", ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"],
    ],
    [
      "https://api.bitme.sh/file", ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"],
    ],
    [
      "https://api.bitme.sh/tempcontrol", ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"],
    ],
    [
      "https://tmeshdev.bitme.sh", ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"],
    ],
    [
      "https://api.bitme.sh/checklist", ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"],
    ],
    [
      "https://api.bitme.sh/security", ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"],
    ],
  ]),
};
