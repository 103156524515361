import * as React from "react";
import { Dispatch, useCallback, useReducer } from "react";
import { Guid } from "../../system/guid";
import {
  BASE_CHECKLIST_MEDIA_PATH,
  useAddSubPoint,
  useBreakageType,
  useChecklistComment,
  useCheckPoint,
  useImageUpload,
  useMeasurementType,
  usePointLabeled,
  useRemoveSubPoint,
  useReopenSession,
  useReportChecklistDeviation,
  useReportDeviation,
  useReportTemperatureDeviation,
  useStartChecklist,
  useStopChecklist,
  useUncheckPoint,
} from "./checklistService";

export const actions = {
  START: "start",
  STOP: "stop",
  CHECK_POINT: "checkPoint",
  UNCHECK_POINT: "uncheckPoint",
  REPORT_DEVIATION: "reportDeviatision",
  REPORT_TEMPERATURE_DEVIATION: "reportTemperatureDeviation",
  SELECT_SESSION: "selectSesson",
  CONTINUE_SESSION: "continueSesion",
  SESSION_COMPLETED: "sessionCompleted",
  REOPEN_SESSION: "reopenSession",
  CHECKLIST_REPORT_DEVIATION: "checklistReportDeviation",
  TYPE_MEASUREMENT: "typeMeasurement",
  TYPE_BREAKAGE: "typeBreakage",
  LABEL_POINT: "typeLabel",
  MEASUREMENT_DEVIATION: "measurementDeviation",
  CHECKLIST_COMMENT: "checklistComment",
  ADD_SUB_POINT: "addSubPoint",
  REMOVE_SUB_POINT: "removeSubPoint"
};

export enum ChecklistState {
  NEW,
  PREVIEW,
  IN_PROGRESS,
  COMPLETED,
  OVERDUE,
}

type MeasurementType = {
  pointId: string;
  measurement: number;
  deviation?: string;
};

type BreakageType = {
  pointId: string;
  amount: number;
};

type State = {
  state: ChecklistState;
  sessionId: string;
  tickedPoints: Array<string>;
  measurements: any;
  breakages: any;
  subPointFetch: boolean;
  reload: boolean;
  changeUrl: string;
  refetch: boolean;
};

const initialState: State = {
  state: ChecklistState.NEW,
  sessionId: "",
  tickedPoints: [],
  measurements: {},
  breakages: {},
  subPointFetch: false,
  reload: false,
  changeUrl: "",
  refetch: false,
};
type MyReducer = (s: State, a: any) => any;
export const useChecklistReducer = (): [State, Dispatch<any>] => {
  let [state, dispatch] = useReducer(
    useCallback((state, action) => {
      if (action.type !== "test") {
        state.reload = false;
        state.refetch = false;
      }
      switch (action.type) {
        case "test":
          return {
            ...state,
            ...action.payload,
            refetch: true,
          };
        case actions.START:
          const sessionId =
            action.payload.sessionId ?? Guid.newGuid().toString();

          useStartChecklist({
            checklistId: action.payload.checklistId,
            tenantId: action.payload.tenantId.toString(),
            assignedId: action.payload.assignedId,
            siteId: action.payload.siteId,
            sessionId: sessionId,
            userId: action.payload.userId,
            username: action.payload.username,
            signatureId: action.payload.signatureId,
          }).then((data) => {
            dispatch({ type: "test" });
          });

          return {
            ...state,
            sessionId: sessionId,
            state: ChecklistState.IN_PROGRESS,
          };

        case actions.STOP:
          // console.log("stop!");
          // const keys = Object.keys(state.measurements);
          // console.log(keys);

          useStopChecklist({
            checklistId: action.payload.checklistId,
            tenantId: action.payload.tenantId.toString(),
            assignedId: action.payload.assignedId,
            sessionId: state.sessionId,
            userId: action.payload.userId,
            siteId: action.payload.siteId,
            signatureId: action.payload.signatureId,
          }).then((data) => {
            dispatch({ type: "test", payload: { changeUrl: "/checklist" } });
          });
          return {
            ...state,
            sessionId: "",
            state: ChecklistState.NEW,
            tickedPoints: [],
          };

        case actions.SELECT_SESSION:
          console.log(action.payload);
          return {
            ...state,
            state: ChecklistState.PREVIEW,
            sessionId: action.payload.sessionId,
            tickedPoints: action.payload.tickedPoints,
            measurements: action.payload.measurements,
            breakages: action.payload.breakages,
            changeUrl: "",
          };

        case actions.CONTINUE_SESSION:
          return {
            ...state,
            state: ChecklistState.IN_PROGRESS,
            changeUrl: "",
          };

        case actions.TYPE_MEASUREMENT:
          const payload = action.payload;
          const { pointId } = payload;
          if (!payload.fetch) {
            useMeasurementType({
              checklistId: payload.checklistId,
              assignedId: payload.assignedId,
              tenantId: payload.tenantId.toString(),
              pointId: pointId,
              entityId: payload.entityId,
              sessionId: state.sessionId,
              userId: payload.userId,
              signatureId: payload.signatureId,
              siteId: payload.siteId,
              measurement:
                payload.measurement === "" ? null : payload.measurement,
            });
          }

          let measurements = state.measurements;
          const value = parseFloat(payload.measurement);
          measurements[pointId] = {
            value: value,
          };

          if (value > payload?.rules?.max || value < payload?.rules?.min) {
            measurements[pointId].deviation = {
              comment: `Temperaturen ble målt til ${value}. Den skal være mellom ${payload.rules.min} og ${payload.rules.max}`,
            };

            // dispatch({
            //   type: actions.REPORT_DEVIATION,
            //   payload: {
            //     deviation: {
            //       comment: `Temperaturen ble målt til ${value}. Den skal være mellom ${payload.rules.min} og ${payload.rules.max}`,
            //       assignedId: payload.assignedId,
            //       checklistId: payload.checklistId,
            //       signatureId: payload.signatureId,
            //       siteId: payload.siteId,
            //       pointId,
            //     },
            //     userId: payload.userId,
            //   },
            // });
          }

          return {
            ...state,
            measurements,
          };

        case actions.TYPE_BREAKAGE:
          console.log(action.payload);
          if (!action.payload.fetch) {
            useBreakageType({
              checklistId: action.payload.checklistId,
              assignedId: action.payload.assignedId,
              tenantId: action.payload.tenantId.toString(),
              pointId: action.payload.pointId,
              unit: action.payload.unit,
              amount: action.payload.amount,
              sessionId: state.sessionId,
              userId: action.payload.userId,
              siteId: action.payload.siteId,
              signatureId: action.payload.signatureId,
            });
          }

          let breakages = state.breakages;
          const amount = parseFloat(action.payload.amount);
          breakages[action.payload.pointId] = {
            value: amount,
          };

          return {
            ...state,
            breakages,
          };

        case actions.LABEL_POINT:
          usePointLabeled({
            checklistId: action.payload.checklistId,
            assignedId: action.payload.assignedId,
            tenantId: action.payload.tenantId.toString(),
            pointId: action.payload.pointId,
            labelType: action.payload.labelType,
            name: action.payload.name,
            sessionId: action.payload.sessionId,
            userId: action.payload.userId,
            siteId: action.payload.siteId,
            signatureId: action.payload.signatureId,
          })
          return state;

        case actions.ADD_SUB_POINT: {
          useAddSubPoint({
            checklistId: action.payload.checklistId,
            assignedId: action.payload.assignedId,
            tenantId: action.payload.tenantId.toString(),
            pointId: action.payload.pointId,
            subPointId: action.payload.subPointId,
            sessionId: action.payload.sessionId,
            userId: action.payload.userId,
            siteId: action.payload.siteId,
            signatureId: action.payload.signatureId,
          })
          let subPointFetch = true;
          return {
            ...state,
            subPointFetch
          }
        }

        case actions.REMOVE_SUB_POINT: {
          useRemoveSubPoint({
            checklistId: action.payload.checklistId,
            assignedId: action.payload.assignedId,
            tenantId: action.payload.tenantId.toString(),
            pointId: action.payload.pointId,
            subPointId: action.payload.subPointId,
            sessionId: action.payload.sessionId,
            userId: action.payload.userId,
            siteId: action.payload.siteId,
            signatureId: action.payload.signatureId
          })
          return state;
        }

        case actions.CHECK_POINT:
          useCheckPoint({
            checklistId: action.payload.checklistId,
            tenantId: action.payload.tenantId.toString(),
            assignedId: action.payload.assignedId,
            pointId: action.payload.pointId,
            sessionId: state.sessionId,
            userId: action.payload.userId,
            siteId: action.payload.siteId,
            signatureId: action.payload.signatureId,
          }).then((data) => {
            // dispatch({ type: "test" });
          });
          return {
            ...state,
            tickedPoints: [...state.tickedPoints, action.payload.pointId],
          };

        case actions.UNCHECK_POINT:
          useUncheckPoint({
            checklistId: action.payload.checklistId,
            tenantId: action.payload.tenantId.toString(),
            assignedId: action.payload.assignedId,
            pointId: action.payload.pointId,
            sessionId: state.sessionId,
            userId: action.payload.userId,
            siteId: action.payload.siteId,
            signatureId: action.payload.signatureId,
          }).then((data) => {
            // dispatch({ type: "test" });
          });
          return {
            ...state,
            tickedPoints: state.tickedPoints.filter((el) => {
              return el !== action.payload.pointId;
            }),
          };

        case actions.REPORT_DEVIATION: {
          useReportDeviation({
            ...action.payload.deviation,
            sessionId: state.sessionId,
            siteId: action.payload.siteId,
            userId: action.payload.userId,
          }).then((data) => {
            // dispatch({ type: "test" });
          });
          return state;
        }

        case actions.REPORT_TEMPERATURE_DEVIATION: {
          useReportTemperatureDeviation({
            ...action.payload.deviation,
            sessionId: state.sessionId,
            siteId: action.payload.siteId,
            userId: action.payload.userId,
          }).then((data) => {
            // dispatch({ type: "test" });
          });
          return state;
        }

        case actions.CHECKLIST_REPORT_DEVIATION: {
          useReportChecklistDeviation({
            ...action.payload.deviation,
            sessionId: state.sessionId,
            userId: action.payload.userId,
            signatureId: action.payload.signatureId,
            siteId: action.payload.siteId,
          }).then((data) => {
            // dispatch({ type: "test" });
          });
          return state;
        }

        case actions.CHECKLIST_COMMENT: {
          useChecklistComment({
            ...action.payload.data,
            sessionId: state.sessionId,
            userId: action.payload.userId,
            signatureId: action.payload.signatureId,
            siteId: action.payload.siteId,
          }).then((data) => {
            // dispatch({ type: "test" });
          });
          return state;
        }

        case actions.SESSION_COMPLETED:
          return {
            ...state,
            state: ChecklistState.COMPLETED,
          };

        case actions.REOPEN_SESSION:
          useReopenSession({
            ...action.payload,
            sessionId: state.sessionId,
          }).then((data) => {
            dispatch({
              type: "test",
              payload: { changeUrl: "/checklist" },
            });
          });
          const newState = {
            ...state,
            state: ChecklistState.IN_PROGRESS,
          };
          return newState;
      }
    }, []),
    initialState
  );
  return [state, dispatch];
};

export const SingleChecklistContext = React.createContext<{
  state: State;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const useSingleChecklistContext = () =>
  React.useContext(SingleChecklistContext);
